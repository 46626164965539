<template>
  <div class="body container">
    <Breadcrumb />
    <h1>{{ this.pageTitle }}</h1>
    <hr class="separate-line" />
    <CreatePaymentForm
      v-if="formModel === 'Add'"
      :disableTeacherSelector="!isRoleAdmin()"
    />
    <EditPaymentForm
      v-if="formModel === 'Edit'"
      :disableTeacherSelector="true"
    />
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
import Breadcrumb from "@/components/Breadcrumb";
import CreatePaymentForm from "@/views/payment/form/Create.vue";
import EditPaymentForm from "@/views/payment/form/Edit.vue";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    CreatePaymentForm,
    EditPaymentForm
  },
  mixins: [moneyMixin, roleMixin],
  computed: {
    pageTitle() {
      if (this.formModel === "Add") {
        return this.$t("pageTitle.createPayment");
      } else if (this.formModel === "Edit") {
        return this.$t("pageTitle.editPayment");
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      payments: [],
      formModel: this.$route.name.indexOf("create") !== -1 ? "Add" : "Edit"
    };
  }
};
</script>

<style scoped>
::v-deep .el-pagination {
  margin: 20px 0px;
}

::v-deep .el-step__description {
  padding-right: 0px;
}
</style>
