var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form-item',{attrs:{"required":"","label":"Bank name","prop":"paymentInformation.bankName","rules":[_vm.commonRules.input]}},[_c('el-input',{attrs:{"value":_vm.payment.bankName},on:{"input":value => {
          this.$emit('setPayment', { bankName: value });
        }}})],1),_c('el-form-item',{attrs:{"required":"","label":"Bank branch","prop":"paymentInformation.bankBranch","rules":[_vm.commonRules.input]}},[_c('el-input',{attrs:{"value":_vm.payment.bankBranch},on:{"input":value => {
          this.$emit('setPayment', { bankBranch: value });
        }}})],1),_c('el-form-item',{attrs:{"required":"","label":"Account holder's name","prop":"paymentInformation.accountName","rules":[_vm.commonRules.input]}},[_c('el-input',{attrs:{"value":_vm.payment.accountName},on:{"input":value => {
          this.$emit('setPayment', { accountName: value });
        }}})],1),_c('el-form-item',{attrs:{"required":"","label":"Account number","prop":"paymentInformation.accountNumber","rules":[_vm.commonRules.input]}},[_c('el-input',{attrs:{"value":_vm.payment.accountNumber},on:{"input":value => {
          this.$emit('setPayment', { accountNumber: value });
        }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }