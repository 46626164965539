export class PaymentMethod {
  constructor(
    teacherId = null,
    paymentMethod = "US Check",
    mainPayment = true,
    note = "",
    payment
  ) {
    this.teacherId = teacherId;
    this.paymentMethod = paymentMethod;
    this.mainPayment = Boolean(mainPayment);
    this.note = note;
    this.paymentInformation = this.getPaymentInformation(payment);
  }

  getPaymentInformation(payment) {
    switch (this.paymentMethod) {
      case "US Check":
        return new USCheck(payment);
      case "Bank account":
        return new BankAccount(payment);
      case "Alipay":
        return new Alipay(payment);
      case "Wechat pay":
        return new Wechat(payment);
      case "Cash":
        return new Cash(payment);
      case "Zelle":
        return new Zelle(payment);
    }
  }
}

class USCheck {
  constructor(
    payment = {
      nameOnCheck: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: ""
    }
  ) {
    this.nameOnCheck = payment.nameOnCheck;
    this.address1 = payment.address1;
    this.address2 = payment.address2;
    this.city = payment.city;
    this.state = payment.state;
    this.zip = payment.zip;
  }
}

class BankAccount {
  constructor(
    payment = {
      bankName: "",
      bankBranch: "",
      accountName: "",
      accountNumber: ""
    }
  ) {
    this.bankName = payment.bankName;
    this.bankBranch = payment.bankBranch;
    this.accountName = payment.accountName;
    this.accountNumber = payment.accountNumber;
  }
}

class Alipay {
  constructor(
    payment = {
      name: "",
      phone: ""
    }
  ) {
    this.name = payment.name;
    this.phone = payment.phone;
  }
}

class Wechat {
  constructor(
    payment = {
      id: ""
    }
  ) {
    this.id = payment.id;
  }
}

class Zelle {
  constructor(
    payment = {
      email: "",
      phone: ""
    }
  ) {
    this.email = payment.email;
    this.phone = payment.phone;
  }
}

class Cash {
  constructor() {}
}
