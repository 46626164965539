<template>
  <div class="body container">
    <el-form label-width="180px" :model="payment" ref="form">
      <el-form-item
        required
        label="Teacher"
        prop="teacherId"
        :rules="[commonRules.input]"
      >
        <TeacherSelector
          :disabled="true"
          :value="payment.teacherId"
          @change="
            teacher => {
              payment = { ...payment, teacherId: teacher.id };
            }
          "
        />
      </el-form-item>
      <el-form-item required label="Payment method">
        <el-select
          style="width: 100%"
          :value="payment.paymentMethod"
          @change="changePaymentMethod(payment.paymentMethod, $event)"
        >
          <el-option label="US-Check" value="US Check" />
          <el-option label="Taiwan-Bank account" value="Bank account" />
          <el-option label="China-Alipay" value="Alipay" />
          <el-option label="China-Wechat pay" value="Wechat pay" />
          <el-option label="Cash" value="Cash" />
        </el-select>
      </el-form-item>
      <USCheckForm
        v-if="payment.paymentMethod === 'US Check'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <BankAccountForm
        v-if="payment.paymentMethod === 'Bank account'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <AlipayForm
        v-if="payment.paymentMethod === 'Alipay'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <WechatForm
        v-if="payment.paymentMethod === 'Wechat pay'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <el-form-item label="Note">
        <el-input
          :value="payment.note"
          @input="
            value => {
              payment = { ...payment, note: value };
            }
          "
        />
      </el-form-item>
      <el-form-item label="Main payment method">
        <el-checkbox
          :value="payment.mainPayment"
          @change="
            value => {
              payment = { ...payment, mainPayment: value };
            }
          "
        />
      </el-form-item>
    </el-form>
    <el-row class="footerButton" :gutter="20">
      <el-col :span="24" style="text-align: center">
        <el-button type="primary" @click="createTeacherPayment">
          SAVE
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";
import { PaymentMethod } from "./Payment";
import { TeacherSelector } from "@/components/selector";
import USCheckForm from "@/views/payment/form/USCheck";
import BankAccountForm from "@/views/payment/form/BankAccountForm";
import AlipayForm from "@/views/payment/form/Alipay";
import WechatForm from "@/views/payment/form/Wechat";
import paymentApi from "@/apis/payment";

export default {
  components: {
    TeacherSelector,
    USCheckForm,
    BankAccountForm,
    AlipayForm,
    WechatForm
  },
  mixins: [formMixin],
  data() {
    return {
      payment: null
    };
  },
  async created() {
    await this.initialPayment();
  },
  methods: {
    async initialPayment() {
      const {
        teacher_user_payment: teacherPayment
      } = await paymentApi.fetchTeacherPayment(
        this.$route.params.teacherPaymentId
      );
      let payment = {};
      teacherPayment.payment.forEach(paymentInfo => {
        payment = {
          ...payment,
          ...paymentInfo
        };
      });

      this.payment = new PaymentMethod(
        teacherPayment.teacher_user_id,
        teacherPayment.title,
        teacherPayment.is_default,
        teacherPayment.note,
        payment
      );
    },
    changePaymentMethod(paymentMethod, newPaymentMethod) {
      if (paymentMethod === newPaymentMethod) return;
      this.payment = new PaymentMethod(
        this.payment.teacherId,
        newPaymentMethod,
        this.payment.mainPayment
      );
    },
    setPaymentInformation(value) {
      this.payment = {
        ...this.payment,
        paymentInformation: {
          ...this.payment.paymentInformation,
          ...value
        }
      };
    },
    async createTeacherPayment() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      const payment = {
        title: this.payment.paymentMethod,
        is_default: this.payment.mainPayment,
        note: this.payment.note,
        payment: Object.keys(this.payment.paymentInformation).map(key => ({
          [key]: this.payment.paymentInformation[key]
        }))
      };

      try {
        await paymentApi.updateTeacherPayment(
          this.$route.params.teacherPaymentId,
          payment
        );

        this.$message({
          message: this.$t("message.update_success"),
          type: "success"
        });
        this.$router.go(-1);
      } catch (e) {
        this.$message({
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss">
.footerButton {
  margin: 20px 0px;
}
</style>
