<template>
  <div class="breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>
        <router-link :to="{ name: 'Home' }">
          <i class="fas fa-home" />
        </router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :key="breadcrumb.name"
        v-for="(breadcrumb, index) in breadcrumbList"
      >
        <router-link
          v-if="
            $slots.restPage ||
              $slots.lastPage ||
              index !== breadcrumbList.length - 1
          "
          :to="routeTo(breadcrumb)"
        >
          {{ breadcrumb.text || $t(`pageTitle.${breadcrumb.name}`) }}
        </router-link>
        <span v-else>
          {{ breadcrumb.text || $t(`pageTitle.${breadcrumb.name}`) }}
        </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$slots.lastPage">
        <slot name="lastPage"></slot>
      </el-breadcrumb-item>
      <slot name="restPage" />
    </el-breadcrumb>
  </div>
</template>

<script>
import roleMixin from "@/mixins/role";

export default {
  props: ["customBreadcrumbList"],
  data() {
    return { breadcrumbList: [] };
  },
  mixins: [roleMixin],
  watch: {
    customBreadcrumbList() {
      this.updateBreadcrumb();
    },
    $route() {
      this.updateBreadcrumb();
    }
  },
  created() {
    this.updateBreadcrumb();
  },
  methods: {
    routeTo(breadcrumb) {
      if (breadcrumb.path) {
        return { path: breadcrumb.path };
      }
      return { name: breadcrumb.name };
    },
    updateBreadcrumb() {
      this.breadcrumbList = this.filterRole(
        this.customBreadcrumbList || this.$route.meta.breadcrumb
      );
    },
    filterRole(breadcrumbList) {
      if (this.isRoleAdmin()) return breadcrumbList;
      return breadcrumbList.filter(
        breadcrumb => breadcrumb.name !== "adminHome"
      );
    }
  }
};
</script>

<style scoped>
.breadcrumb {
  background: #f5f5f5;
  margin: 20px 0px;
}
::v-deep .el-breadcrumb {
  line-height: 1.5;
}
</style>
