<template>
  <div>
    <el-form-item
      required
      label="Name on check"
      prop="paymentInformation.nameOnCheck"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.nameOnCheck"
        @input="
          value => {
            this.$emit('setPayment', { nameOnCheck: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="Address1"
      prop="paymentInformation.address1"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.address1"
        @input="
          value => {
            this.$emit('setPayment', { address1: value });
          }
        "
      />
    </el-form-item>
    <el-form-item label="Address2">
      <el-input
        :value="payment.address2"
        @input="
          value => {
            this.$emit('setPayment', { address2: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="City"
      prop="paymentInformation.city"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.city"
        @input="
          value => {
            this.$emit('setPayment', { city: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="State"
      prop="paymentInformation.state"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.state"
        @input="
          value => {
            this.$emit('setPayment', { state: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="Zip"
      prop="paymentInformation.zip"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.zip"
        @input="
          value => {
            this.$emit('setPayment', { zip: value });
          }
        "
      />
    </el-form-item>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    payment: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style scoped>
.rowsGap {
  margin: 20px 0px;
}
</style>
