<template>
  <div>
    <el-form-item
      required
      label="Bank name"
      prop="paymentInformation.bankName"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.bankName"
        @input="
          value => {
            this.$emit('setPayment', { bankName: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="Bank branch"
      prop="paymentInformation.bankBranch"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.bankBranch"
        @input="
          value => {
            this.$emit('setPayment', { bankBranch: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="Account holder's name"
      prop="paymentInformation.accountName"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.accountName"
        @input="
          value => {
            this.$emit('setPayment', { accountName: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="Account number"
      prop="paymentInformation.accountNumber"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.accountNumber"
        @input="
          value => {
            this.$emit('setPayment', { accountNumber: value });
          }
        "
      />
    </el-form-item>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    payment: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style scoped>
.rowsGap {
  margin: 20px 0px;
}
</style>
