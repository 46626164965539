import ApiService from "@/common/api.service";

export default {
  fetchTeacherPayments(teacherId) {
    return ApiService.query("teacher-user-payments", {
      teacher_user_id: teacherId
    });
  },
  fetchTeacherPayment(teacherPaymentId) {
    return ApiService.query(`teacher-user-payments/${teacherPaymentId}`);
  },
  createTeacherPayment(data) {
    return ApiService.post("teacher-user-payments", data);
  },
  updateTeacherPayment(teacherPaymentId, payment) {
    return ApiService.put(`teacher-user-payments/${teacherPaymentId}`, payment);
  },
  deleteTeacherPayments(teacherPaymentId) {
    return ApiService.delete(`teacher-user-payments/${teacherPaymentId}`);
  }
};
