<template>
  <div>
    <el-form-item
      required
      label="ID"
      prop="paymentInformation.id"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.id"
        @input="
          value => {
            this.$emit('setPayment', { id: value });
          }
        "
      />
    </el-form-item>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: ["payment"]
};
</script>
