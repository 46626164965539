<template>
  <div>
    <el-form-item
      required
      label="Name"
      prop="paymentInformation.name"
      :rules="[commonRules.input]"
    >
      <el-input
        :value="payment.name"
        @input="
          value => {
            this.$emit('setPayment', { name: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="Phone"
      prop="paymentInformation.phone"
      :rules="[commonRules.phone]"
    >
      <el-input
        :value="payment.phone"
        @input="
          value => {
            this.$emit('setPayment', { phone: value });
          }
        "
      />
    </el-form-item>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: ["payment"]
};
</script>
