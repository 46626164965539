<template>
  <div class="body container">
    <el-form label-width="180px" :model="payment" ref="form">
      <el-form-item
        required
        label="Teacher"
        prop="teacherId"
        :rules="[commonRules.input]"
      >
        <TeacherSelector
          :disabled="disableTeacherSelector"
          :value="payment.teacherId"
          @change="
            teacher => {
              payment = { ...payment, teacherId: teacher.id };
            }
          "
        />
      </el-form-item>
      <el-form-item required label="Payment method">
        <el-select
          style="width: 100%"
          :value="payment.paymentMethod"
          @change="
            value => {
              payment = { ...payment, paymentMethod: value };
            }
          "
        >
          <el-option label="US-Check" value="US Check" />
          <el-option label="Taiwan-Bank account" value="Bank account" />
          <el-option label="China-Alipay" value="Alipay" />
          <el-option label="China-Wechat pay" value="Wechat pay" />
          <el-option label="Cash" value="Cash" />
          <el-option label="Zelle" value="Zelle" />
        </el-select>
      </el-form-item>
      <USCheckForm
        v-if="payment.paymentMethod === 'US Check'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <BankAccountForm
        v-if="payment.paymentMethod === 'Bank account'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <AlipayForm
        v-if="payment.paymentMethod === 'Alipay'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <WechatForm
        v-if="payment.paymentMethod === 'Wechat pay'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <ZelleForm
        v-if="payment.paymentMethod === 'Zelle'"
        :payment="payment.paymentInformation"
        @setPayment="setPaymentInformation"
      />
      <el-form-item label="Note">
        <el-input
          :value="payment.note"
          @input="
            value => {
              payment = { ...payment, note: value };
            }
          "
        />
      </el-form-item>
      <el-form-item label="Main payment method">
        <el-checkbox
          :value="payment.mainPayment"
          @change="
            value => {
              payment = { ...payment, mainPayment: value };
            }
          "
        />
      </el-form-item>
    </el-form>
    <el-row class="footerButton" :gutter="20">
      <el-col :span="24" style="text-align: center">
        <el-button type="primary" @click="createTeacherPayment">
          ADD
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";
import { PaymentMethod } from "./Payment";
import { TeacherSelector } from "@/components/selector";
import USCheckForm from "@/views/payment/form/USCheck";
import BankAccountForm from "@/views/payment/form/BankAccountForm";
import AlipayForm from "@/views/payment/form/Alipay";
import WechatForm from "@/views/payment/form/Wechat";
import ZelleForm from "@/views/payment/form/Zelle";
import paymentApi from "@/apis/payment";

export default {
  components: {
    TeacherSelector,
    USCheckForm,
    BankAccountForm,
    AlipayForm,
    WechatForm,
    ZelleForm
  },
  props: {
    disableTeacherSelector: {
      type: Boolean,
      default: false
    }
  },
  mixins: [formMixin],
  watch: {
    "payment.paymentMethod"(paymentMethod) {
      this.payment = new PaymentMethod(this.payment.teacherId, paymentMethod);
    }
  },
  data() {
    return {
      payment: null
    };
  },
  async created() {
    this.initialPayment();
  },
  methods: {
    initialPayment() {
      if (this.$route.params.teacherId) {
        this.payment = new PaymentMethod(Number(this.$route.params.teacherId));
      } else {
        this.payment = new PaymentMethod();
      }
    },
    setPaymentInformation(value) {
      this.payment = {
        ...this.payment,
        paymentInformation: {
          ...this.payment.paymentInformation,
          ...value
        }
      };
    },
    async createTeacherPayment() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      const payment = {
        teacher_user_id: this.payment.teacherId,
        title: this.payment.paymentMethod,
        is_default: this.payment.mainPayment,
        note: this.payment.note,
        payment: Object.keys(this.payment.paymentInformation).map(key => ({
          [key]: this.payment.paymentInformation[key]
        }))
      };

      try {
        await paymentApi.createTeacherPayment(payment);
        this.$message({
          message: this.$t("message.create_success"),
          type: "success"
        });

        this.$router.go(-1);
      } catch (e) {
        this.$message({
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss">
.footerButton {
  margin: 20px 0px;
}
</style>
