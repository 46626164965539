<template>
  <div>
    <el-form-item
      required
      label="Email"
      prop="paymentInformation.email"
      :rules="[commonRules.email]"
    >
      <el-input
        :value="payment.email"
        @input="
          value => {
            this.$emit('setPayment', { email: value });
          }
        "
      />
    </el-form-item>
    <el-form-item
      required
      label="Phone"
      prop="paymentInformation.phone"
      :rules="[commonRules.phone]"
    >
      <el-input
        :value="payment.phone"
        @input="
          value => {
            this.$emit('setPayment', { phone: value });
          }
        "
      />
    </el-form-item>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: ["payment"]
};
</script>
